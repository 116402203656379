<template>
  <div>
    <!-- type 2 -->
    <div v-if="messageItem.messageResponse.content.responseType == 2">
      <ChatBoxTextButton
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type 3, 5 -->
    <div
      v-else-if="
        messageItem.messageResponse.content.responseType == 3 ||
          messageItem.messageResponse.content.responseType == 5
      "
    >
      <ChatBoxTextImg
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type 6 -->
    <div
      class="container"
      v-else-if="messageItem.messageResponse.content.responseType == 6"
    >
      <ChatBoxFlexCarousel
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type 7 -->
    <div
      class="container-img-flex"
      v-else-if="messageItem.messageResponse.content.responseType == 7"
    >
      <ChatBoxImageCarousel :messageItem="messageItem" />
    </div>

    <!-- type 9 Location -->
    <div v-else-if="messageItem.messageResponse.content.responseType == 9">
      <ChatBoxLocation
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type 10 Tracking -->
    <div v-else-if="messageItem.messageResponse.content.responseType == 10">
      <ChatBoxTracking
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type 11 Location Detail -->
    <div v-else-if="messageItem.messageResponse.content.responseType == 11">
      <ChatBoxLocationDetail
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type 18 Tracking List -->
    <div v-else-if="messageItem.messageResponse.content.responseType == 18">
      <ChatBoxTrackingList
        @send-postback-trackno="sendPostbackTrackNo"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>

    <!-- type ? QR Code -->
    <div v-else-if="messageItem.messageResponse.content.responseType == 19">
      <ChatBoxQRCode
        @send-message="sendMessage"
        @setShowMap="setShowMap"
        :messageItem="messageItem"
      />
    </div>
  </div>
</template>

<script>
import ChatBoxTextButton from "./ChatBoxTextButton.vue";
import ChatBoxTextImg from "./ChatBoxTextImg.vue";
import ChatBoxFlexCarousel from "./ChatBoxFlexCarousel.vue";
import ChatBoxImageCarousel from "./ChatBoxImageCarousel.vue";
import ChatBoxLocation from "./ChatBoxLocation.vue";
import ChatBoxTracking from "./ChatBoxTracking.vue";
import ChatBoxLocationDetail from "./ChatBoxLocationDetail.vue";
import ChatBoxTrackingList from "./ChatBoxTrackingList.vue";
import ChatBoxQRCode from "./ChatBoxQRCode.vue";

export default {
  name: "ChatBoxFlex",
  components: {
    ChatBoxTextButton,
    ChatBoxTextImg,
    ChatBoxFlexCarousel,
    ChatBoxImageCarousel,
    ChatBoxLocation,
    ChatBoxTracking,
    ChatBoxLocationDetail,
    ChatBoxTrackingList,
    ChatBoxQRCode,
  },
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    sendMessage(dataSend, type, textShow) {
      if (textShow) {
        this.$emit("send-message", dataSend, type, "", textShow);
      } else {
        this.$emit("send-message", dataSend, type);
      }
    },
    sendPostbackTrackNo(dataSend, type, textShow) {
      this.$emit("send-message", dataSend, type, "", textShow);
    },
    setShowMap(show) {
      this.$emit("setShowMap", show);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 12px;
}
.container-img-flex {
  padding: 0px;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89,90,92, 0.25);
  outline: none;
}
</style>
