<template>
  <div>
    <!-- Type 2 Text Button -->
    <div
      class="container-frame"
      v-if="messageItem.messageResponse.content.responseType == 2"
    >
      <div class="container-item">
        <span
          v-html="
            messageItem.messageResponse.content.content.text.replace(
              /(?:\r\n|\r|\n)/g,
              '<br />'
            )
          "
          class="msg_wrap"
        >
          {{
          messageItem.messageResponse.content.content.text.replace(/(?:\r\n|\r|\n)/g,
          '<br />') }}
        </span>
      </div>
      <hr
        v-if="
          messageItem.messageResponse.content.content.buttons &&
            messageItem.messageResponse.content.content.buttons.length > 0 &&
            messageItem.messageResponse.content.content.buttons.some(
              (contenBtn) => contenBtn.Type != 2
            )
        "
      />
      <div
        v-for="(btn, j) in messageItem.messageResponse.content.content.buttons"
        :key="j"
        class="column container-item"
        v-show="btn.Type != 2"
      >
        <button
          v-if="btn.Action != 8"
          type="button"
          class="list-btn"
          v-bind:style="{ backgroundColor: themeColor }"
          @click="
            () => {
              handleClickButton(btn.Action, btn.Label, btn.Data);
            }
          "
        >
        <!-- cool -->
        <!-- v-bind:style="[
            btn.Color
              ? { backgroundColor: btn.Color }
              : { backgroundColor: themeColor },
          ]" -->
          {{ btn.Label }}
        </button>
        <a
          v-else
          class="list-btn btn-tel"
          v-bind:style="{ backgroundColor: themeColor }"
          :href="`tel:${btn.Label}`"
        >
          {{ btn.Label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config.js";
export default {
  name: "ChatBoxTextButton",
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      themeColor: config.color,
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.msg_wrap {
  overflow-wrap: break-word;
  color: #595a5c;
  font-size: 20px;
}
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
}
.container-frame {
  padding-top: 10px;
  padding-bottom: 5px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
.list-btn {
  border-radius: 0.5rem;
  margin-bottom: 5px;
  color: white;
  border: 0px;
  padding: 0.4rem;
  font-size: 20px;
  font-family: "DBHeavent";
}
.list-btn:hover {
  cursor: pointer;
}
.column {
  display: flex;
  flex-flow: column;
}
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.container-text-title-top {
  margin-top: 0px;
}
.title-black {
  color: #3a3937;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.btn-tel {
  text-decoration-line: none;
  text-align: center;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}
</style>
